import * as actionTypes from "../../../../store/actions";
import produce from "immer";
import {TrialManagementState} from "../interfaces/trial-management.state";
import {SiteStatusEnum} from "../../../../shared/enums/siteStatusEnum";
import {User, UserPermission, UserPermissionTypes} from "../../../../api/connect";
import {UserPermissionTypesEnum} from "../../../../shared/permissions/enums/user-permission-types.enum";
import {TrialAssociatedUserModel} from "../models/trial-associated-user.model";
import {permissionsToRole} from "../../../../shared/permissions/functions/permission-template.function";
import {UserPermissionsEnum} from "../../../../shared/permissions/enums/user-permissions.enum";
import {LocationModel} from "../../../../shared/components/location-selectors/models/location.model";
import {PermissionStatusEnum} from "../enums/permission-status.enum";
import {LocationTargetsInterface} from "../interfaces/location-targets.interface";
import {TrialTargetTypeEnum} from "../enums/trial-target-type.enum";
import {UnknownAction} from "redux";
import {TRIAL_MANAGEMENT_LOADED_EVRIPATH_THERAPEUTICS} from "../../../../store/actions";

const initialState = {
    isLoadingTrials: true,
    trials: [],
    selectedTrial: null,
    selectedEvripath: null,
    isSelectingTrial: false,
    isLoadingUsers:false,
    users:[],
    isChangingRole: null,
    isSavingDetails: false,
    sites: [],
    companySites: [],
    isLoadingEvripathDetails: false,
    isLoadingSites: false,
    isLoadingCompanySites: false,
    isSavingLinkedSite: false,
    loadingTrialStatuses: false,
    trialSiteStatuses: [],
    loadingSiteStatuses: false,
    siteTrialStatuses: [],
    siteUnlinkedError: false,
    isSavingDqReasons: false,
    isLoadingDqReasons: false,
    dqReasons: [],
    isSavingWdReasons: false,
    isLoadingWdReasons: false,
    wdReasons: [],
    therapeuticAreas: []
};

const trialManagementReducer = (state: TrialManagementState = initialState, action: UnknownAction) => {
    switch ((action as any).type) {
        case actionTypes.TRIAL_MANAGEMENT_LOADED_EVRIPATH_THERAPEUTICS:
            return produce(state, draft => {
                draft.therapeuticAreas = (action as any).areas;
            });
        case actionTypes.TRIAL_MANAGEMENT_LOCATION_TARGET_CHANGE:
            return produce(state, draft => {

                // const site = draft.sites.find(s => s.id === (action as any).siteId);
                // if(site) {
                //     const targets = site.locationTargets;
                //     const target = targets?.find((t: LocationTargetsInterface) => t.trialId === (action as any).trialId && t.type === (action as any).targetType);
                //     if(target) {
                //         target.targetValue = (action as any).targetValue;
                //     }
                //
                // }

            });
        case actionTypes.TRIAL_MANAGEMENT_DQ_REASONS_LOAD:
            return produce(state, draft => {
                draft.isLoadingDqReasons = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_DQ_REASONS_LOADED:
            return produce(state, draft => {
                draft.dqReasons = (action as any).reasons;
                draft.isLoadingDqReasons = false;
            });
        case actionTypes.TRIAL_MANAGEMENT_DQ_REASONS_ADD:
            return produce(state, draft => {
                draft.isSavingDqReasons = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_DQ_REASONS_ADDED:
            return produce(state, draft => {
                draft.isSavingDqReasons = false;
            });


        case actionTypes.TRIAL_MANAGEMENT_WD_REASONS_LOAD:
            return produce(state, draft => {
                draft.isLoadingWdReasons = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_WD_REASONS_LOADED:
            return produce(state, draft => {
                draft.wdReasons = (action as any).reasons;
                draft.isLoadingWdReasons = false;
            });
        case actionTypes.TRIAL_MANAGEMENT_WD_REASONS_ADD:
            return produce(state, draft => {
                draft.isLoadingWdReasons = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_WD_REASONS_ADDED:
            return produce(state, draft => {
                draft.isLoadingWdReasons = false;
            });
        case actionTypes.TRIAL_MANAGEMENT_SAVE_INVITED_USERS:
            return produce(state, draft => {
               draft.isLoadingUsers = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_DECLINE_INVITATION:
            return produce(state, draft => {
                const user = draft.users.find((u: TrialAssociatedUserModel) => u.id === (action as any).removeUserId);
                if (user) {
                    user.isRemoving = true;
                }
            });
        case actionTypes.TRIAL_MANAGEMENT_INVITATION_DECLINED:
            return produce(state, draft => {
                const user = draft.users.find((u: TrialAssociatedUserModel) => u.id === (action as any).removeUserId);
                if (user) {
                    user.isRemoving = false;
                }
                draft.users = draft.users.filter((u: TrialAssociatedUserModel) => u.id !== (action as any).removeUserId);
            });
        case actionTypes.TRIAL_MANAGEMENT_USER_REMOVE:
            return produce(state, draft => {
                const user = draft.users.find((u: TrialAssociatedUserModel) => u.id === (action as any).removeUserId);
                if (user) {
                    user.isRemoving = true;
                }
            });
        case actionTypes.TRIAL_MANAGEMENT_USER_REMOVED:
            return produce(state, draft => {
                const user = draft.users.find((u: TrialAssociatedUserModel) => u.id === (action as any).removeUserId);
                if (user) {
                    user.isRemoving = false;
                }
                draft.users = draft.users.filter((u: TrialAssociatedUserModel) => u.id !== (action as any).removeUserId);
            });
        case actionTypes.TRIAL_MANAGEMENT_UPDATE_DETAILS:
            return produce(state, draft => {
                draft.isSavingDetails = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_UPDATED_DETAILS:
            return produce(state, draft => {
                draft.isSavingDetails = false;
            });
        case actionTypes.TRIAL_MANAGEMENT_UPDATE_EVRIPATH_DETAILS:
            return produce(state, draft => {
                draft.isSavingDetails = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_UPDATED_EVRIPATH_DETAILS:
            return produce(state, draft => {
                draft.isSavingDetails = false;
            });
        case actionTypes.TRIAL_MANAGEMENT_LOAD_EVRIPATH_DETAILS:
            return produce(state, draft => {
                draft.isLoadingEvripathDetails = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_LOADED_EVRIPATH_DETAILS:
            return produce(state, draft => {
                draft.isLoadingEvripathDetails = false;
                draft.selectedEvripath = (action as any).details;
            });
        case actionTypes.TRIAL_MANAGEMENT_ROLE_CHANGE:
            return produce(state, draft => {
                const users = JSON.parse(JSON.stringify(draft.users)); // force state change
                draft.isChangingRole = (action as any).changingUserRole.id;
                const user = users.find((u: TrialAssociatedUserModel) => u.id === (action as any).changingUserRole.id);
                if (user) {
                    user.isChangingRole = true;
                    user.locations = [];
                }
                draft.users = users;
            });
        case actionTypes.TRIAL_MANAGEMENT_ROLE_CHANGED:
            return produce(state, draft => {
                const user = draft.users.find((u: TrialAssociatedUserModel) => u.id === (action as any).changingUserRole.id);
                if (user) {
                    user.isChangingRole = false;
                    user.role = (action as any).changingUserRole.newRole;
                    user.permissions = (action as any).changingUserRole.updatedPermissions;
                }

                draft.isChangingRole = null;
            });
        case actionTypes.TRIAL_MANAGEMENT_LOCATION_CHANGED:
            return produce(state, draft => {
                const user = draft.users.find((u: TrialAssociatedUserModel) => u.id === (action as any).changingUserRole.id);
                if (user) {
                    user.permissions = (action as any).changingUserRole.updatedPermissions;
                }
            });
        case actionTypes.TRIAL_MANAGEMENT_USERS_LOAD:
            return produce(state, draft => {
                draft.isLoadingUsers = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_USERS_LOADED:
            return produce(state, draft => {

                const userPermissions = (action as any).users;
                draft.users = userPermissions ? userPermissions.map((u: User) => {
                    const permissions = u.permissions ? u.permissions?.filter((p: UserPermission) => p.type === UserPermissionTypesEnum.Trial as unknown as UserPermissionTypes && p.resourceId === draft.selectedTrial?.id) : [];

                    return new TrialAssociatedUserModel(
                        u.cognitoSub,
                        `${u.firstName} ${u.lastName}`,
                        `${u.firstName?.charAt(0)} ${u.lastName?.charAt(0)}`,
                        u.email,
                        u.company ? u.company.name as string : '-',
                        permissionsToRole(permissions.flatMap((p: UserPermission) => p.permission) as unknown as UserPermissionsEnum[], UserPermissionTypesEnum.Trial),
                        u.recordStatus,
                        permissions,
                        permissions.filter(p => p.parentResourceId as number > 0 && p.parentResourceId).map((p: UserPermission) => {
                            const location = new LocationModel();
                            location.id = p.parentResourceId;
                            location.name = p.parentResourceName as string;
                            return location;
                        }),
                        (action as any).sites.map((s:any) => {
                            const location = new LocationModel();
                            location.id = s.id;
                            location.name= s.name;
                            location.addressLine1= s.addressLine1;
                            location.addressLine2 = s.addressLine2;
                            location.postcode = s.postcode;
                            location.state = s.state;
                            return location;
                        }),
                        permissions.length ? permissions[0].status as unknown as PermissionStatusEnum : PermissionStatusEnum.Pending,
                        false,
                        false
                    )
                }) : [];
                draft.isLoadingUsers = false;
            });
        case actionTypes.TRIAL_MANAGEMENT_LOAD:
            return produce(state, draft => {
                draft.isLoadingTrials = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_LOADED:
            return produce(state, draft => {
                draft.isLoadingTrials = false;
                draft.trials = (action as any).trials;
            });
        case actionTypes.TRIAL_MANAGEMENT_SELECT_TRIAL:
            return produce(state, draft => {
                draft.isSelectingTrial = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_SELECTED_TRIAL:
            return produce(state, draft => {
                draft.selectedTrial = (action as any).trial;
                draft.isSelectingTrial = false;
            });
        case actionTypes.TRIAL_MANAGEMENT_UNSELECT_TRIAL:
            return produce(state, draft => {
                draft.selectedTrial = null;
                draft.selectedEvripath = null;
            });
        case actionTypes.TRIAL_MANAGEMENT_LINK_SITE:
        case actionTypes.TRIAL_MANAGEMENT_UNLINK_SITE:
            return produce(state, draft => {
                draft.isSavingLinkedSite = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_SITE_LINKED:
        case actionTypes.TRIAL_MANAGEMENT_SITE_UNLINKED:
            return produce(state, draft => {
                draft.isSavingLinkedSite = false;
            });
        case actionTypes.TRIAL_MANAGEMENT_SITE_STATUSES_LOAD:
            return produce(state, draft => {
                draft.loadingSiteStatuses = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_SITE_STATUSES_LOADED:
            return produce(state, draft => {
                draft.loadingSiteStatuses = false;
                draft.siteTrialStatuses = [...(action as any).siteTrialStatuses.filter((t:any) => ![SiteStatusEnum.REMOVED].includes(t.status))].map(t => { return { id: t.id, name: t.name, status: t.status, siteId: t.siteId, trialIdentifier: draft.trials?.find(x => x.id === t.id)?.identifier} })
            });
        case actionTypes.TRIAL_MANAGEMENT_TRIAL_STATUSES_LOAD:
            return produce(state, draft => {
                draft.loadingTrialStatuses = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_TRIAL_STATUSES_LOADED:
            return produce(state, draft => {
                draft.loadingTrialStatuses = false;
                draft.trialSiteStatuses = [...(action as any).sites.filter((s:any) =>  ![SiteStatusEnum.REMOVED].includes(s.status))].map(s => { return { id: s.siteId, name: s.siteName, status: s.status, trialId: s.trialId} })
            });
        case actionTypes.TRIAL_MANAGEMENT_SITES_LOAD:
            return produce(state, draft => {
                draft.isLoadingSites = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_SITES_LOADED:
            return produce(state, draft => {
                draft.sites = ((action as any).sites) ? [...(action as any).sites.sort((a:any, b:any) =>  {
                   if(a.name && b.name) {
                       let fa = a.name.toLowerCase(),
                           fb = b.name.toLowerCase();

                       if (fa < fb) {
                           return -1;
                       }
                       if (fa > fb) {
                           return 1;
                       }
                   }
                    return 0;
                })] : [];

                draft.sites = [...draft.sites.map(s => {
                    return {
                        ...s,
                        refTarget: s.locationTargets?.find((t: LocationTargetsInterface) => t.trialId === draft.selectedTrial?.id && t.type === TrialTargetTypeEnum.Referral)?.targetValue || 0,
                        enrTarget: s.locationTargets?.find((t: LocationTargetsInterface) => t.trialId === draft.selectedTrial?.id && t.type === TrialTargetTypeEnum.Enrolment)?.targetValue || 0
                    }
                })];

                draft.isLoadingSites = false;
                draft.isSavingLinkedSite = false;
            });
        case actionTypes.TRIAL_MANAGEMENT_COMPANY_SITES_LOAD:
            return produce(state, draft => {
                draft.isLoadingCompanySites = true;
            });
        case actionTypes.TRIAL_MANAGEMENT_COMPANY_SITES_LOADED:
            return produce(state, draft => {
                draft.companySites = ((action as any).sites) ? [...(action as any).sites.sort((a:any, b:any) =>  {
                    if(a.name && b.name) {
                        let fa = a.name.toLowerCase(),
                            fb = b.name.toLowerCase();

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                    }
                    return 0;
                })] : [];

                draft.isLoadingCompanySites = false;
            });
        case actionTypes.TRIAL_MANAGEMENT_SITE_UNLINKED_FAILED:
            return produce(state, draft => {
                draft.siteUnlinkedError = true;
                draft.isSavingLinkedSite = false;
            });
        case actionTypes.TRIAL_MANAGEMENT_SITE_UNLINKED_FAILED_RESET:
            return produce(state, draft => {
                draft.siteUnlinkedError = false;
                draft.isSavingLinkedSite = false;
            });
        default:
            return state;
    }
};

export default trialManagementReducer;